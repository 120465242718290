<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="secondary--text mb-10">Detalhes Cadastro de Procedimento</h1>
      </v-col>
    </v-row>
    <v-form ref="formRegister" lazy-validation>
      <v-row class="mt-10">
        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
          <label class="label">Tabela</label>
          <v-autocomplete
            :items="carriers"
            item-text="xipp_commercial_name"
            item-value="xipp_commercial_name"
            :rules="[rule.required]"
            class="mt-2"
            placeholder="Selecione a tabela"
            v-model="procedure.table"
            :loading="isLoadingCarrier"
            outlined
            :disabled="isLoadingCarrier || (!isEdit && !isInsert)"
            color="textPrimary"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
          <label class="label">Código Original</label>
          <v-text-field
            v-model="procedure.originalCode"
            :disabled="!isEdit && !isInsert"
            :rules="[rule.required]"
            class="mt-2"
            placeholder="Código Original"
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
          <label class="label">Descrição Original</label>
          <v-text-field
            v-model="procedure.originalDescription"
            :disabled="!isEdit && !isInsert"
            :rules="[rule.required]"
            class="mt-2"
            placeholder="Descrição Original"
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
          <label class="label">Código TUSS</label>
          <v-text-field
            v-model="procedure.tussCode"
            :disabled="!isEdit && !isInsert"
            class="mt-2"
            placeholder="Código TUSS"
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
          <label class="label">Descrição TUSS</label>
          <v-text-field
            v-model="procedure.tussDescription"
            :disabled="!isEdit && !isInsert"
            class="mt-2"
            placeholder="Descrição TUSS"
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
          <label class="label">Grupo</label>
          <v-text-field
            v-model="procedure.group"
            :disabled="!isEdit && !isInsert"
            class="mt-2"
            placeholder="Grupo"
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
          <label class="label">Subgrupo</label>
          <v-text-field
            v-model="procedure.subGroup"
            :disabled="!isEdit && !isInsert"
            class="mt-2"
            placeholder="Subgrupo"
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
          <label class="label">Capítulo</label>
          <v-text-field
            v-model="procedure.chapter"
            :disabled="!isEdit && !isInsert"
            class="mt-2"
            placeholder="Capítulo"
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
          <label class="label">Rol</label>
          <v-text-field
            v-model="procedure.rol"
            :disabled="!isEdit && !isInsert"
            class="mt-2"
            placeholder="Rol"
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
          <label class="label">Grupo de Procedimento</label>
          <v-text-field
            v-model="procedure.procedureGroup"
            :disabled="!isEdit && !isInsert"
            class="mt-2"
            placeholder="Grupo de Procedimento"
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6">
          <label class="label">Complemento</label>
          <v-text-field
            v-model="procedure.complement"
            :disabled="!isEdit && !isInsert"
            class="mt-2"
            placeholder="Complemento"
            :rules="[rule.validateNotSpecialCharacters]"
            outlined
            color="textPrimary"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="mt-7 justify-end">
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-btn
          class="float-right"
          color="textPrimary"
          dark
          block
          min-height="47"
          @click="onClickCancel()"
        >
          Voltar
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3" v-if="isEdit || isInsert">
        <v-btn
          class="float-right"
          color="textPrimary"
          dark
          block
          min-height="47"
          @click="onClickSave()"
        >
          {{ isEdit ? "Atualizar" : "Salvar" }}
        </v-btn>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import Functions from '@/shared/functions/functions';
import Rules from '@/shared/validators/formRules';
import Formatters from '@/shared/formatters/formatters';
import CarrierService from '@/services-http/contract/CarrierService';
import ProcedureControlCodeService from '@/services-http/analytics/ProcedureControlCodeService';

export default {
  name: 'ProcedureRegisterForm',
  data() {
    return {
      snackbar: {
        show: false,
        text: '',
        type: '',
      },
      procedure: {
        originalDescription: null,
        ogirinalCode: null,
        tussCode: null,
        tussDescription: null,
        subGroup: null,
        group: null,
        chapter: null,
        rol: null,
        table: null,
        procedureGroup: null,
        complement: null,
      },
      payload: {},
      overlay: false,
      isEdit: false,
      isInsert: false,
      isLoadingCarrier: false,
      carriers: [],
    };
  },
  methods: {
    async loadDataSessionStorage() {
      this.payload = JSON.parse(sessionStorage.getItem('procedure'));
      this.procedure = {
        chapter: this.payload.capitulo,
        originalCode: this.payload.cdOriginal,
        tussCode: this.payload.cdTuss,
        originalDescription: this.payload.descOriginal,
        tussDescription: this.payload.descTuss,
        group: this.payload.grupo,
        subGroup: this.payload.subgrupo,
        procedureGroup: this.payload.grupoProcedimento,
        table: this.payload.tabela,
        rol: this.payload.rol,
        complement: this.payload.complemento,
      };
    },
    getTypeForm() {
      if (this.$route.query.isEdit) {
        this.isEdit = true;
      }
      if (this.$route.query.isInsert) {
        this.isInsert = true;
      }
    },
    async onClickSave() {
      if (!this.$refs.formRegister.validate()) {
        return;
      }
      const payload = {
        capitulo: this.procedure.chapter,
        cdOriginal: this.procedure.originalCode,
        cdTuss: this.procedure.tussCode,
        descOriginal: this.procedure.originalDescription,
        descTuss: this.procedure.tussDescription,
        grupo: this.procedure.group,
        subgrupo: this.procedure.subGroup,
        grupoProcedimento: this.procedure.procedureGroup,
        tabela: this.procedure.table,
        rol: this.procedure.rol,
        complemento: this.procedure.complement,
      };
      if (this.isInsert) {
        this.insert(payload);
      }
      if (this.isEdit) {
        this.update(payload);
      }
    },
    async insert(payload) {
      await this.ProcedureControlCodeService.Insert(payload).then(() => {
        this.customizeSnackbarMessage('success', 'Cadastro de procedimento realizado com sucesso!');
        setTimeout(() => {
          sessionStorage.removeItem('procedure');
          this.cleanFilters();
          this.$router.push({ name: 'ProcedureRegisterSearch' });
        }, 3000);
      }).catch(() => {
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao realizar o cadastro de procedimento');
      });
    },
    async update(payload) {
      const procedure = JSON.parse(sessionStorage.getItem('procedure'));
      procedure.payload = payload;
      await this.ProcedureControlCodeService.Update(procedure).then(() => {
        this.customizeSnackbarMessage('success', 'Atualização de procedimento realizado com sucesso!');
        setTimeout(() => {
          sessionStorage.removeItem('procedure');
          this.cleanFilters();
          this.$router.push({ name: 'ProcedureRegisterSearch' });
        }, 3000);
      }).catch(() => {
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao atualizar o procedimento');
      });
    },
    onClickCancel() {
      this.$router.push({ name: 'ProcedureRegisterSearch' });
    },
    cleanFilters() {
      this.procedure = {
        originalDescription: null,
        ogirinalCode: null,
        tussCode: null,
        tussDescription: null,
        subGroup: null,
        group: null,
        chapter: null,
        rol: null,
        table: null,
        procedureGroup: null,
        complement: null,
      };
      this.payload = {};
    },
    async loadCarrierService() {
      this.overlay = true;
      this.isLoadingCarrier = true;
      await this.carrierService.FindAll().then((response) => {
        if (response && response.data) {
          this.isLoadingCarrier = false;
          this.carriers = response.data;
        }
      });
      this.overlay = false;
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
  },
  async created() {
    this.rule = new Rules();
    this.formatter = new Formatters();
    this.functions = new Functions();
    this.carrierService = new CarrierService();
    this.ProcedureControlCodeService = new ProcedureControlCodeService();
    this.getTypeForm();
    await this.loadCarrierService();
    if (!this.isInsert) {
      await this.loadDataSessionStorage();
    }
  },
};
</script>
